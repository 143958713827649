exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakty-tsx": () => import("./../../../src/pages/kontakty.tsx" /* webpackChunkName: "component---src-pages-kontakty-tsx" */),
  "component---src-pages-material-tsx": () => import("./../../../src/pages/material.tsx" /* webpackChunkName: "component---src-pages-material-tsx" */),
  "component---src-pages-polygrafie-tsx": () => import("./../../../src/pages/polygrafie.tsx" /* webpackChunkName: "component---src-pages-polygrafie-tsx" */),
  "component---src-pages-splintovaci-kleste-tsx": () => import("./../../../src/pages/splintovaci-kleste.tsx" /* webpackChunkName: "component---src-pages-splintovaci-kleste-tsx" */),
  "component---src-pages-sublimace-tsx": () => import("./../../../src/pages/sublimace.tsx" /* webpackChunkName: "component---src-pages-sublimace-tsx" */),
  "component---src-pages-symboly-tsx": () => import("./../../../src/pages/symboly.tsx" /* webpackChunkName: "component---src-pages-symboly-tsx" */),
  "component---src-pages-synteticka-kuze-tsx": () => import("./../../../src/pages/synteticka-kuze.tsx" /* webpackChunkName: "component---src-pages-synteticka-kuze-tsx" */),
  "component---src-pages-termotransfer-tsx": () => import("./../../../src/pages/termotransfer.tsx" /* webpackChunkName: "component---src-pages-termotransfer-tsx" */),
  "component---src-pages-tisk-z-folie-tsx": () => import("./../../../src/pages/tisk-z-folie.tsx" /* webpackChunkName: "component---src-pages-tisk-z-folie-tsx" */),
  "component---src-pages-tkane-etikety-tsx": () => import("./../../../src/pages/tkane-etikety.tsx" /* webpackChunkName: "component---src-pages-tkane-etikety-tsx" */),
  "component---src-pages-transfery-tsx": () => import("./../../../src/pages/transfery.tsx" /* webpackChunkName: "component---src-pages-transfery-tsx" */),
  "component---src-pages-vzornik-barev-tsx": () => import("./../../../src/pages/vzornik-barev.tsx" /* webpackChunkName: "component---src-pages-vzornik-barev-tsx" */)
}

